.App {
  background-color: rgb(255, 255, 255);
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-family: 'EB Garamond', sans-serif;
  font-size: calc(5px + 2vmin);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.App-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(0, 0, 0);
  height: 80px;
  top: 0;
  padding-left: 2vw;
  padding-right: 2vw;
}

.Menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.Menu button { 
  background-color: white;
  font-size: calc(5px + 2vmin);
  height: 100%;
  padding-right: 1vw;
  padding-left: 1vw;
  border: transparent;
  color: #212121;
}
.Menu button:hover {
  background-color: gray;
  color: white;
}
.Menu a { 
  display: flex;
  align-items: center;
  font-size: calc(5px + 2vmin);
  height: 100%;
  padding-right: 1vw;
  padding-left: 1vw;
  border: transparent;
  color: #212121;
  text-decoration: none;
}
.Menu a:hover {
  background-color: gray;
  color: white;
  text-align: center;
}

.Title {
  height: 5vw;
  max-height: 58px;
}

.SidePanel {
  position:absolute;
  z-index: 1000;
  background-color: #212121;
}

.PageBtnLeft {
  left: 0;
  position:absolute;
  z-index: 10;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  border: none;
  padding-left: 5px;
}
.PageBtnLeft:disabled {
  display: none;
}

.PageBtnRight {
  right: 0px;
  position:absolute;
  z-index: 10;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0);
  border: none;
  padding-right: 5px;
}
.PageBtnRight:disabled {
  display: none;
}

.Pulsate:hover {
  transform: scale(1.1);
  transition-duration: 0.25s;
}

.react-horizontal-scrolling-menu--wrapper {
  height: calc(100vh - 80px);
}

.Section {
  height: calc(100vh - 80px);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Centered {
  width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.Introduction {
  width: 95vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background-color: white;
  margin-left: 100px;
  margin-right: 100px;
}

.max-height {
  max-width: 90vw;
  height: 100%;
}

@media (min-width: 1072px)
{
  .Centered {
    width: 90vw;
  }

  .Introduction {
    width: 70vw;
    flex-direction: row;
    align-items: flex-start;
  }
}

@media (min-width: 1300px)
{
  .Centered {
    width: 70vw;
  }
}

.App-body {
  color: rgb(0, 0, 0);
  padding-top: 50px;
  z-index: 1;
  position: absolute;
  background-color: #ffffff;
}

.App-banner {
  min-height: calc(100vh - 80px);
  min-width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.List {
  padding-top: 10px;
}

.About {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 160px);
}

.Heading {
  color: white;
  font-size: calc(50px + 15vmin);
  border: #212121;
  text-shadow: 4px 4px 10px rgb(0, 0, 0);
}

.Subheading {
  color: white;
  font-size: calc(20px + 4vmin);  
  text-shadow: 4px 4px 10px rgb(0, 0, 0);
  text-align: center;
}

.wave {
  animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;        /* Change to speed up or slow down */
  animation-iteration-count: infinite;  /* Never stop waving :) */
  transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: calc(50px + 10vmin);
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.Experience {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5vh;
  margin-bottom: 5vh;
}

.Experiences {
  height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Desc_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Desc_title {
  font-weight: 500;
  font-size: calc(10px + 2vmin);
}

.Desc_duration {
  font-size: calc(5px + 2vmin);
}

.ColumnX1 {
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.ColumnX3 {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.Project {
  height: calc(100vh - 160px);
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Grid {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 3vw;
  flex-wrap: wrap;
}

.Card {
  width: 15vw;
  height: 50vh;
  font-family: 'Lato', sans-serif;
  text-align: left;
  overflow: hidden;
  padding: 0;
  box-shadow: 0px 0px 2px rgb(0, 0, 0);
  background-color: black;
}

@media (min-width: 1482px)
{
  .Card {
    height: 60vh;
  }
}

.Card_Image {
  width: 100%;
  height: 100%;
  background-position: 50% 75%;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  opacity: 1;
  background-blend-mode: multiply;
}
.Card_Image:hover{
  animation: fadeDark;
  animation-duration: 0.3s;
  background-color: rgba(0, 0, 0, 0.7);
}

@keyframes fadeDark {
  0% { background-color: rgba(0, 0, 0, 0); }
  100% { background-color: rgba(0, 0, 0, 0.7); }
}

.Desc_Title {
  padding: 10px;
  font-size: calc(10px + 1vmin);
  font-weight: 500;
  opacity: 0;
}
.Card:hover .Desc_Title{
  animation: fadeIn;
  animation-duration: 0.3s;
  opacity: 1;
}

.Desc_Info {
  padding: 10px;
  display: inline-block;
  font-size: calc(8px + 1vmin);
  font-weight: 300;
  padding-top: 10px;
  opacity: 0;
}
.Card:hover .Desc_Info{
  animation: fadeIn;
  animation-duration: 0.3s;
  opacity: 1;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.Document {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.Document a {
  align-self: center;
  padding-left: 30px;
}

.Header {
  text-align: center;
  font-weight: bold;
  padding-top: 5vh;
  padding-bottom: 1vh;
}

.White {
  color: white;
}

.Contacts {
  height: calc(100vh - 160px);
  width: 100%;
  color: white;
  background-color: #212121;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 80px;
}

.Contact {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.Icon {
  background-color: #212121;
  width: 27px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 5px;
}

.Points {
  margin-top: 10px;
  font-size: calc(7px + 1vh);
}